import React, { useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import projectsData from "../components/project.json";
import { createBrowserHistory } from "history";
// import Login from "../components/Login";

const Navbar = () => {
  const history = createBrowserHistory({ forceRefresh: true });
  const [isOpen, setOpen] = useState(false);

  const isAuth = localStorage.getItem("userData");

  const loginUser = () => {
    history.push("/Login");
  };
  const handleNavigate=()=>{
    if(isAuth){
      history.push("/Home");
    }
    else{
      history.push("/");
    }
  }

  const logoutUser = () => {
    sessionStorage.clear();
    localStorage.clear();
    history.push("/Login");
  };
  const proj = projectsData.projects;

  const getnavpages = (proj) => {
    let content = [];
    for (let i = 0; i < proj.length; i++) {
      const item = proj[i];

      content.push(
        <NavLink
          key={item.id}
          className="navbar-item"
          activeClassName="is-active"
          to={item.page}
          exact
        >
          {item.button}
        </NavLink>
      );
    }
    return content;
  };

  return (
    <nav
      className="navbar is-link"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div
          className="navbar-brand"
          onClick={() => {
            handleNavigate();
          }}
        >
         <img className="image" src={require('../components/images/APE_LOGO.jpg') } width='80' height='60'/>
          <a
            role="button"
            className={`navbar-burger burger ${isOpen && "is-active"}`}
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setOpen(!isOpen)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className={`navbar-menu ${isOpen && "is-active"}`}>
          <div className="navbar-start">
            {!isAuth ? (
              <NavLink
                key="home"
                className="navbar-item"
                activeClassName="is-active"
                to="/"
                exact
              >
                Home
              </NavLink>
            ) : (
              <NavLink
                key="home"
                className="navbar-item"
                activeClassName="is-active"
                to="/Home"
                exact
              >
                Home
              </NavLink>
            )}

            {getnavpages(proj)}

            <NavLink
              key="Info"
              className="navbar-item"
              activeClassName="is-active"
              to="/Info"
            >
              Scores
            </NavLink>
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                {!isAuth ? (
                  <button
                    className="button is-white"
                    onClick={() => {
                      loginUser();
                    }}
                  >
                    Log in
                  </button>
                ) : (
                  <button className="button is-black" onClick={logoutUser}>
                    Log out
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default withRouter(Navbar);
