import React, { useState } from "react";
import { useHistory,useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../Utilities/axios";
import toast from "react-hot-toast";
 
const Subscription = () => {
  const history = useHistory()
  const location = useLocation();
  
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("plan_id");
  const [loader, setLoader] = useState(false);

  const subSchema = Yup.object().shape({
    users_email: Yup.string()
      .email("Invalid email address")
      .required("Email is required")});


  const formikSubscription = useFormik({
    initialValues: {
      users_email: "",
    },
    validationSchema: subSchema,
    onSubmit: async (values) => {
      setLoader(true);
      try {
        const formData = new FormData();
        formData.append("users_email", values?.users_email);
        formData.append("plan", id);
        const response = await axiosInstance.post("/checkout", formData);
        console.log(response,'sub--------->>')
        if (response?.data?.status) {
            window.location.href = response?.data?.url
            setLoader(false);
        }
        else{
          toast.success(response?.data?.response,{duration:3000});
          setLoader(false);
        }
      } catch (e) {
        setLoader(false);
        console.log(e);
        if(e?.response === 'you already have an account and you can login'){
          toast.success(e?.response,{duration:3000});
          history.push('/Login')
        }
        else{
          toast.error(e?.response);
        }
      }
    },
  });


    return (
      <div className="container">
        <form className="Auth-form py-4" onSubmit={formikSubscription.handleSubmit}>
          <div className="Auth-form">
            <h3 className="Auth-form-title">Subscription</h3>
            <div className="text-center">
              Subscription Buy{" "}
            </div>
            <div className="form-group mt-3 text-start">
              <label htmlFor="email">Email address</label>
              <input
                className="form-control mt-1"
                placeholder="Email Address"
                type="email"
                name="users_email"
                onChange={formikSubscription.handleChange}
                onBlur={formikSubscription.handleBlur}
                value={formikSubscription.values.users_email}
              />
              {formikSubscription.touched.users_email &&
              formikSubscription.errors.users_email ? (
                <div
                  className="error text-start"
                  style={{ color: "red", fontSize: "12px", paddingLeft: "4px" }}
                >
                  {formikSubscription.errors.users_email}
                </div>
              ) : null}
            </div>
      
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="form-submit-button" disabled={loader}>{loader ? 'Loading...' :'Submit'}
              </button>
            </div>
          </div>
        </form>
      </div>
    );



};

export default Subscription;
