import React from "react";

const MoneyBack = () => {
  return (
    <>
      <h1 className="section-heading py-5">100% Money-Back Pass Guarantee</h1>
      <div className="container">
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          At Apex Prime Education LLC, we are fully committed to your success.
          That’s why we offer a <b>100% Money-Back Pass Guarantee</b> if you do
          not pass your NMLS exam on your first attempt. We’ll refund you{" "}
          <b>$175 toward your next exam</b> if you meet the following
          conditions:
        </p>
        <p className="section-heading text-start fs-5 pt-4 pb-2">
          To qualify for our Pass Guarantee, you must:
        </p>
       <div className="pl-5">
       <p class="list">
          <b>Watch 100% of the training content.</b>
        </p>
        <p class="list">
          <b>
            Download all applicable study materials, including the Study Guide.
          </b>
        </p>
        <p class="list">
          <b>Achieve at least 90% on all quizzes, exams, and practice tests.</b>
        </p>
        <p class="list">
          <b>Complete all assigned course tasks.</b>
        </p>
        <p class="list">
          <b>Take your NMLS exam within 90 days of enrolling in the course.</b>{" "}
          and provide proof of the exam date.
        </p>
       </div>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          We track student progress on the backend, so ensure you meet all these
          requirements. Simply watching a few videos or completing the practice
          exam without fully engaging in the material will not prepare you for
          the NMLS exam, nor will it qualify you for this guarantee.
        </p>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          If you meet all the requirements above and still do not pass your exam
          on the first attempt:
        </p>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
            
          1. Email us at <a href="mailto:blackplagueprecision@gmail.com" target="_blank" rel="noopener noreferrer">
  blackplagueprecision@gmail.com
</a> with:{" "}
        </p>
        <div className="pl-5">
        <p class="list">
          Proof that your first exam was taken within 90 days of enrolling in
          the course.
        </p>
        <p class="list">A photo of your failed exam result.</p>
        <p class="list">A receipt for your new exam registration.</p>
        </div>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          This guarantee does not apply to discounted purchases, such as Black
          Friday deals or promotional offers. Refunds are only valid for the
          NMLS Exam Prep course and are not transferable to other products
        </p>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          At Apex Prime Education LLC, we stand by the effectiveness of our
          program, and this guarantee is a reflection of our commitment to your
          success!{" "}
        </p>
      </div>
    </>
  );
};

export default MoneyBack;
