import React, { useState, useEffect } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import "./content_page.css";
import subsData from "../components/subtopics.json";
import $ from "jquery";
import TimeQuiz from "./Random_Quiz";
import axiosInstance from "../Utilities/axios";
import toast from "react-hot-toast";

const Quiz = () => {
  const [step, setStep] = useState(0);
  const [btnid, setBtnid] = useState("");
  const sub_sub = subsData.sub_section;
  const params = useParams();
  const location = useLocation(); // Gives the current location object
  const queryParams = new URLSearchParams(location.search);

  console.log(params, "queryParams", queryParams);
  const subfilter = sub_sub?.filter((sub_sub) => sub_sub?.id === params?.id);
  const item = subfilter[0];
  // const item = proj[5];
  const [repo, setRepo] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [code_filter, setValue] = useState("");
  const SubToken = localStorage.getItem("SubToken");
  const SubEmail = localStorage.getItem("SubEmail");
  const userData = JSON.parse(localStorage.getItem("userData"));

  const shuffle = (array) => {
    if (!Array.isArray(array) || array.length === 0) {
      return []; // Return an empty array or handle the invalid input appropriately
    }

    let currentIndex = array.length,
      randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!SubToken && !userData) {
      history.push("/Login");
    }
    // else if (!SubToken && params?.id !== "RESPA") {
    //   history.push("/Login");
    // } else if (SubToken && params?.id === "RESPA") {
    // }
  }, [SubToken, userData]);

  useEffect(() => {
    if (buttonClicked) {
      getData();
    }
  }, [buttonClicked]);

  const getData = () => {
    const baseUrl = "/user/getdata";

    const queryParams = SubToken
      ? `?url=/RESPA/Quiz&email=${SubEmail}`
      : "";
    const url = `${baseUrl}${queryParams}`;

    axiosInstance
      .get(url)
      .then(function (response) {
        // if (String(code_filter).length > 6) {
        const data_qa = response?.data?.getdata?.filter(
          (data) => data?.code_structure?.section === String(code_filter?.code)
        );
        const formattedData = data_qa.map((item) => ({
          ...item,
          options: [item.A, item.B, item.C, item.D],
        }));
        const test = shuffle(formattedData);
        const slicedArray = test.slice(0, 150);
        setRepo(slicedArray);
        setBtnid("");
        setButtonClicked(false);
        setStep(2);
      })
      .catch(function (error) {
        setBtnid('')
        setButtonClicked(false);
        console.log(error);
        toast.error(error?.message);
      });
  };

  const handleButtonClick = (e) => {
    setValue(e);
  };
  console.log(buttonClicked, "button state");

  const ItemList = (item) => {
    const items = item?.item?.quizs;
    const itemButtons = items?.map((quiz, index) => (
      <div key={index} style={{ padding: 20 }}>
        <p style={{ paddingBottom: 0 }}>{quiz.name}</p>
        <button
          type="button"
          className="btn"
          onClick={() => {
            setBtnid(index);
            setButtonClicked(true);
            handleButtonClick(quiz);
          }}
        >
          {index === btnid ? "Loading... " : "Click me"}
        </button>
      </div>
    ));

    return (
      <div>
        <h1 className="section-heading">Quiz Time</h1>
        <h3>
          Each Module has its own practice quiz. Please read the following
          instructions carefully before starting:
        </h3>
        <p>
          1. Once you click on an answer, it will be locked in and cannot be
          changed. Do not click on an answer unless you are sure about your
          choice.
        </p>
        <p>
          2. There is no option to save and return to the quiz later. Ensure you
          are fully prepared and ready to complete the quiz before you start.
        </p>
        <p>
          3. Although you need 75% to pass the actual test, we recommend aiming
          for 85% and above on our quiz.
        </p>
        <p>Start the quiz when you are ready.</p>

        {SubToken ? <div style={{ padding: 20 }}>
          <p style={{ paddingBottom: 0 }}>{items[0].name}</p>
          <button
            type="button"
            className="btn"
            onClick={() => {
              setBtnid(items[0].name);
              setButtonClicked(true);
              handleButtonClick(items[0]);
            }}
          >
            {items[0].name === btnid ? "Loading... " : "Click me"}
          </button>
        </div> : itemButtons}

        <a href={"../" + params.id + "/Program"} rel="noreferrer">
          Return to Program Page
        </a>
        <br padding="100px"></br>
      </div>
    );
  };

  return (
    <div>
      {step === 0 && <ItemList item={item} />}
      {step === 2 && (
        <TimeQuiz repo={repo} sectionInfo={code_filter} setonStep={setStep} />
      )}
    </div>
  );
};

export default Quiz;
