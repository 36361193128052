/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */

import React from "react";
import { useHistory } from "react-router-dom";
import axiosInstance from "../Utilities/axios";
import { useEffect, useState } from "react";

const SubscriptionPage = () => {
  const [isLoading, setisLoading] = useState(false);
  const [data, setData] = useState([]);

  const handleSub = async () => {
    setisLoading(true);
    try {
      const res = await axiosInstance.get("/plans");
      setData(res?.data?.data);
      setisLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    handleSub();
  }, []);

  const history = useHistory();
  const routeChange = (element) => {
    history.push(element);
  };
  return (
    <>
      <div className="pt-4">
        <div className="container">
          <div className="row pt-5">
            <div className="col-12">
              <div className="text-center" style={{ textAlign: "start" }}>
                <h3 style={{fontSize:'32px',fontWeight:700}}>
                  Subscription Plan
                </h3>
            
              </div>
            </div>
          </div>
          <div className="row text-center d-flex justify-content-center py-4">
            {isLoading &&
                <div className="col-lg-12" >
                  <p>Loading...</p>
                </div>
              }

            {data?.map((e, i) => (
              <div className="col-lg- " style={{padding:'30px'}}>
              <div style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',padding:'30px',borderRadius:'15px' }}>
              <p style={{fontSize:'32px',fontWeight:700}}>{e?.name}</p>
                <p style={{fontSize:'24px',fontWeight:600}}>${e?.price}</p>
                <button
                  type="button"
                  className="button is-link is-medium mt-4"
                  onClick={(nextClickHandler) => routeChange(`/subscription?plan_id=${e?.id}`)}
                >
                Buy
                </button>
              </div>  
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPage;
