import React from "react";

const Refund = () => {
  return (
    <>
      <h1 className="section-heading py-5">Refund Policy</h1>
      <div className="container">
        <h2
          className="text-start "
          style={{ fontSize: "20px", fontWeight: 600, paddingTop: "10px" }}
        >
          7-Day Partial Refund Policy for Apex Prime Education LLC
        </h2>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          At Apex Prime Education LLC, we prioritize customer satisfaction and
          transparency. Refund requests must be submitted to our customer
          service team within 7 days of purchase. Refunds issued during this
          period will be partial, as all refunds are subject to a non-refundable
          processing fee to account for credit card transaction costs
        </p>
        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          After the 7-day period,
          <b> no refunds will be issued under any circumstances.</b> We strongly
          encourage customers to thoroughly evaluate their decision before
          enrolling in any program to avoid unnecessary charges.{" "}
        </p>

        <p
          className="text-start"
          style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
        >
          This policy is subject to change. For clarifications or assistance,
          please email us at{" "}
          <a href="mailto:blackplagueprecision@gmail.com" target="_blank" rel="noopener noreferrer">
  blackplagueprecision@gmail.com
</a>

          .{" "}
        </p>
      </div>
    </>
  );
};

export default Refund;
